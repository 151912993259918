<template>
    <v-main>
        <v-container class="pb-12">
            <v-row v-if="cart.showOkOrder">
                <v-col cols="12">
                    <FinalizedOrder />
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" class="py-0">
                    <h2
                        :style="{ color: parameters.primaryColor }"
                        class="text-left"
                        :class="$mq != 'lg' ? 'mt-3': 'mt-6'"
                    >
                        Resumo
                    </h2>
                </v-col>
                <v-col cols="12">
                    <v-card outlined min-width="80%" :style="{borderColor: parameters.primaryColor}" elevation="0">
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-title class="text-left button">Produtos no <a :style="{color: parameters.secondaryColor}" @click="$router.push('/carrinho')">Carrinho</a> ({{itensOnCart}})</v-list-item-title>
                                <v-list-item-action class="ml-0 mr-2">{{ totalCart | money }}</v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-card outlined height="auto" min-width="80%" class="py-1 px-2" :style="{borderColor: parameters.primaryColor}" elevation="0">
                        <v-card-text>
                            <v-row dense no-gutters>
                                <v-col cols="12" md="6">
                                    <v-subheader class="font-weight-bold text-h5 px-0">Endereço de entrega</v-subheader>
                                    <v-subheader class="px-0">Confirme seu endereço de entrega.</v-subheader>
                                    <ResumeShippingAddress />
                                </v-col>
                                <v-col cols="12" md="1" v-if="$mq == 'lg'">
                                    <v-divider vertical ></v-divider>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-subheader class="font-weight-bold text-h5 px-0">Observações do pedido</v-subheader>
                                    <v-subheader class="px-0 pb-1 caption">Escreva alguma informação importante para o pedido</v-subheader>
                                    <v-form ref="form" lazy-validation>
                                        <v-textarea
                                            color="primary"
                                            v-model="cart.sale.vd1observa"
                                            placeholder="Ex.: Renovação da loja para o próximo inverno"
                                            solo
                                            name="observation"
                                            height="100px"
                                            counter
                                            :rules="observationRules"
                                        ></v-textarea>
                                    </v-form>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-btn 
                                text
                                :color="parameters.primaryColor"
                                @click="$router.push('/carrinho')"
                            >Voltar</v-btn>
                            <v-btn 
                                outlined
                                :color="parameters.secondaryColor"
                                @click="buy"
                            >Finalizar pedido</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
const ResumeShippingAddress = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/resume/ResumeShippingAddress.vue"
    );
const FinalizedOrder = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/resume/FinalizedOrder.vue"
    );
import { mapGetters } from 'vuex'

export default {
    data: () => ({
        observationRules: [
            v => (!v || (!!v && v.length <= 250)) || "Limite máximo de 250 caracteres"
        ]
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        cart: {
            get() {
                return this.$store.state.cart;
            },
        },
        ...mapGetters({
            itensOnCart: 'cart/itensOnCart',
            totalCart: 'cart/totalCart',
        })
    },
    components:{
        ResumeShippingAddress,
        FinalizedOrder,
    },
    methods:{
        buy(){
            if (this.$refs.form.validate()) {
                this.$store.dispatch("order/buy")
            }
        }
    }
};
</script>

<style>
</style>