<template>
    <v-app-bar
        app
        height="81px"
        class="v-app-bar v-toolbar__content_oryx"
        color="#FDFDFD"
        elevate-on-scroll
        ref="appBar"
    >
        <a @click="$router.push('/')">
            <img
                :src="logo"
                alt="logo"
                height="60px"
            />
        </a>
    </v-app-bar>
</template>

<script>
export default {
    computed:{
        logo(){
            return `../../../../img/${process.env.VUE_APP_ENV_NAME}/logo.png`
        }
    }
};
</script>

<style scoped>
.v-toolbar__content_oryx >>> .v-toolbar__content {
    justify-content: center!important;
}
</style>